import {
  query,
  collection,
  where,
  orderBy,
  getDocs,
  limit,
  setDoc,
  doc,
  deleteDoc,
  writeBatch,
} from 'firebase/firestore'

import { store } from './firebase'
import { COLLECTIONS } from '@constants'
import { Like } from '@models/like'
import { Cosmetic } from '@models/cosmetic'

export async function getLikes({ userId }: { userId: string }) {
  const snapshot = await getDocs(
    query(
      collection(store, COLLECTIONS.LIKE),
      where('userId', '==', userId),
      orderBy('order', 'asc'),
    ),
  )

  return snapshot.docs.map(
    (doc) =>
      ({
        id: doc.id,
        ...doc.data(),
      }) as Like,
  )
}

// 이미 찜이되어있다면 -> 삭제
// 찜 x -> 저장
export async function toggleLike({
  cosmetic,
  userId,
}: {
  cosmetic: Pick<
    Cosmetic,
    | 'name'
    | 'id'
    | 'url'
    | 'price'
    | 'brand_name'
    | 'category'
    | 'comment'
    | 'volume'
    | 'salePercent'
    | 'totalSale'
  >
  userId: string
}) {
  const findSnapshot = await getDocs(
    query(
      collection(store, COLLECTIONS.LIKE),
      where('userId', '==', userId),
      where('cosmeticId', '==', cosmetic.id),
    ),
  )

  // 이미 존재함 => 삭제
  if (findSnapshot.docs.length > 0) {
    // 1, 2(삭제), [3, 4] - 1 => [2, 3]
    const removeTarget = findSnapshot.docs[0]
    const removeTargetOrder = removeTarget.data().order

    const updateTargetSnapshot = await getDocs(
      query(
        collection(store, COLLECTIONS.LIKE),
        where('userId', '==', userId),
        where('order', '>', removeTargetOrder),
      ),
    )

    if (updateTargetSnapshot.empty) {
      return deleteDoc(removeTarget.ref)
    } else {
      const batch = writeBatch(store)

      updateTargetSnapshot.forEach((doc) => {
        batch.update(doc.ref, { order: doc.data().order - 1 })
      })

      await batch.commit()

      return deleteDoc(removeTarget.ref)
    }
  } else {
    // 없음 => 생성

    const lastLikeSnapshot = await getDocs(
      query(
        collection(store, COLLECTIONS.LIKE),
        where('userId', '==', userId),
        orderBy('order', 'desc'),
        limit(1),
      ),
    )

    const lastOrder = lastLikeSnapshot.empty
      ? 0
      : lastLikeSnapshot.docs[0].data().order
    console.log('cosmetic', cosmetic)
    const newLike = {
      order: lastOrder + 1,
      cosmeticId: cosmetic.id,
      cosmeticName: cosmetic.name,
      url: cosmetic.url,
      userId,
      price: cosmetic.price,
      comment: cosmetic.comment,
      brand_name: cosmetic.brand_name,
      volume: cosmetic.volume,
      category: cosmetic.category,
      salePercent: cosmetic.salePercent,
      totalSale: cosmetic.totalSale,
    }

    return setDoc(doc(collection(store, COLLECTIONS.LIKE)), newLike)
  }
}

export function updateOrder(likes: Like[]) {
  const batch = writeBatch(store)

  likes.forEach((like) => {
    batch.update(doc(collection(store, COLLECTIONS.LIKE), like.id), {
      order: like.order,
    })
  })

  return batch.commit()
}
