export const COLLECTIONS = {
  USER: 'USER',
  MAIN: 'MAIN',
  CATEGORYICON: 'CATERYICON',
  COSMETIC: 'COSMETIC',
  BOARD: 'BOARD',
  CHAT: 'CHAT',
  REVIEW: 'REVIEW',
  LIKE: 'LIKE',
} as const
