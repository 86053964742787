export interface YearsOption {
  readonly label: string
  readonly value: string | null
}

export const YEARS = [
  { label: '2000 년', value: '2000' },
  { label: '1999 년', value: '1999' },
  { label: '1998 년', value: '1998' },
  { label: '1997 년', value: '1997' },
  { label: '1996 년', value: '1996' },
  { label: '1995 년', value: '1995' },
  { label: '1994 년', value: '1994' },
  { label: '1993 년', value: '1993' },
  { label: '1992 년', value: '1992' },
  { label: '1991 년', value: '1991' },
  { label: '1990 년', value: '1990' },
  { label: '1989 년', value: '1989' },
  { label: '1988 년', value: '1988' },
  { label: '1987 년', value: '1987' },
  { label: '1986 년', value: '1986' },
  { label: '1985 년', value: '1985' },
  { label: '1984 년', value: '1984' },
  { label: '1983 년', value: '1983' },
  { label: '1982 년', value: '1982' },
  { label: '1981 년', value: '1981' },
  { label: '1980 년', value: '1980' },
]

export const MONTH = [
  { label: '1 월', value: '1' },
  { label: '2 월', value: '2' },
  { label: '3 월', value: '3' },
  { label: '4 월', value: '4' },
  { label: '5 월', value: '5' },
  { label: '6 월', value: '6' },
  { label: '7 월', value: '7' },
  { label: '8 월', value: '8' },
  { label: '9 월', value: '9' },
  { label: '10 월', value: '10' },
  { label: '11 월', value: '11' },
  { label: '12 월', value: '12' },
]

export const DAYS = [
  { label: '1 일', value: '1' },
  { label: '2 일', value: '2' },
  { label: '3 일', value: '3' },
  { label: '4 일', value: '4' },
  { label: '5 일', value: '5' },
  { label: '6 일', value: '6' },
  { label: '7 일', value: '7' },
  { label: '8 일', value: '8' },
  { label: '9 일', value: '9' },
  { label: '10 일', value: '10 일' },
  { label: '11 일', value: '11 일' },
  { label: '12 일', value: '12 일' },
  { label: '13 일', value: '13 일' },
  { label: '14 일', value: '14 일' },
  { label: '15 일', value: '15 일' },
  { label: '16 일', value: '16 일' },
  { label: '17 일', value: '17 일' },
  { label: '18 일', value: '18 일' },
  { label: '19 일', value: '19 일' },
  { label: '20 일', value: '20 일' },
  { label: '21 일', value: '21 일' },
  { label: '22 일', value: '22 일' },
  { label: '23 일', value: '23 일' },
  { label: '24 일', value: '24 일' },
  { label: '25 일', value: '25 일' },
  { label: '26 일', value: '26 일' },
  { label: '27 일', value: '27 일' },
  { label: '28 일', value: '28 일' },
  { label: '29 일', value: '29 일' },
  { label: '30 일', value: '30 일' },
  { label: '31 일', value: '31 일' },
]
